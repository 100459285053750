
/*ads*/


::selection{
  color: #fff;
  background: #9b27ca;
}
.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}
.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;

  background:  #DB1F48;
  transition: background-color 0.8s;
}
.btn:hover .bg-layer{

    background: #4169E1;
}
.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}












.banner h1{
    color: white;
    font-size: 1.3em;
}
.banner{
    text-align: center;
    
    margin-bottom: 60px;
    padding: 10px;
    background-color: #4169E1;
    display: flex;
    flex-direction: column;
    font-family: 'Merriweather Sans',   sans-serif;
    font-size: 0.6em;
    border: none;

}
.banner h1{
	font-size:2.2em;
	padding-top: 20px;
    text-decoration: none;
    position: relative;
    padding-bottom: 20px;
    color: whitesmoke;
    font-family: sans-serif;

}

#highlight{
    background-color: rgb(247, 245, 245);

}
#regularSection{
    margin-top: 60px;
   
    margin: auto;
    margin-left: 20px;
    margin-right: 20px;
}
#regularSection .csharp, .example, p{
    margin: 0px 10px;
    
    
}
#regularSection p{
    line-height: 1.4;
    font-size: 1em;
    margin-right:20px;
    margin-left: 20px;
}
.rules{
    margin:20px;
}

.content-table{
    display: block;
    border-collapse: collapse;
    overflow-x: auto;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 900px;
    margin-left: 30px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 20px rgba(0, 0 , 0, 0.15);
    

}

.content-table thead tr{
    background-color: #004369;
    color: #fff;
    text-align: left;
    font-weight: bold;

    font-family: 'Nunito', sans-serif;


}
.content-table th,
.content-table td{
    padding: 12px 15px;
    



}
.content-table td{
    font-family: sans-serif;
    overflow: hidden; 
    text-overflow: ellipsis; 
    word-wrap: break-word;

}
.content-table tbody tr{
    border-bottom: 1px solid #dddddd;

}
.content-table tbody tr:nth-last-of-type(even){
    background-color: #f3f3f3;
}
.content-table tbody tr:last-of-type{
    border-bottom:  2px solid #004369
}
.content-table tbody tr.active-row{
    font-weight: bold;
    color: #004369;
}
#regularSection{
    margin-left: 20px;
    margin-right: 20px;
}
.tableCon{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    overflow-x:auto;
    
}

#regularSection h3{
    
    width: 100%;
    margin-bottom: 10px;
    margin-top: 30px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;
    font-family: 'Merriweather Sans', sans-serif;


}
#regularImg{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;

}

#overflow{
    overflow-wrap: break-word;
}


#regularSection h4{
    font-size: 0.9em;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right:20px;
    margin-left: 10px;
    font-family: 'Merriweather Sans', sans-serif;

}



@media only screen and (min-width: 768px){
	
    #regularSection{
        margin-top: 100px;
        width: 600px;
        margin: auto;
  
    }
  
	


}
@media only screen and (min-width: 820px){
 
  
    
}
  
@media only screen and (min-width: 1000px){
   
 
    #regularSection{
        width: 700px;
    }

}

