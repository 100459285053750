
.sidenav {
    display: none;
    margin-top: 0px;
    margin-right: 10px;
    margin-left: 0px;
   
    position: absolute; 
    right: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-bottom: 20px;
    height: auto; /* Full-height: remove this if you want "auto" height */
    width: 310px; /* Set the width of the sidebar */

  }
  .adsbygoogle{
    margin-bottom: 20px;
  }
  







.banner{
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 50px;
    background-color: #4169E1;
    display: flex;
    flex-direction: column;
    font-family: 'Merriweather Sans',   sans-serif;
    font-size: 0.6em;



}
h2{
    padding-top:30px;
    padding-bottom: 30px;
	font-size:2.2em;
}


@media only screen and (min-width: 768px){
   
   
    .headContainer h1{
        font-size: 1.2em;
    }
    
    .banner h2{
        font-size: 3em;
    }
    .banner p{
        font-size:2.2em ;
    }
  
   }
@media only screen and (min-width: 820px){
  



}

@media only screen and (min-width: 1200px){
 
    
}



