
.stackSection{
  
    font-family: "euclid_circular_a","Arial","Source Sans Pro","Helvetica Neue","Helvetica","Arial",sans-serif;
    margin: auto;
    margin-left: 20px;
    margin-right: 20px;
    
}

/*ads*/
.sidenav {
    display: none;
    margin-top: 0px;
    margin-right: 10px;
    margin-left: 0px;
   
    position: absolute; 
    right: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-bottom: 20px;
    height: auto; /* Full-height: remove this if you want "auto" height */
    width: 310px; /* Set the width of the sidebar */

  }
  .adsbygoogle{
    margin-bottom: 20px;

  }

::selection{
  color: #fff;
  background: #9b27ca;
}
.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}
.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;

  background:  #DB1F48;
  transition: background-color 0.8s;
}
.btn:hover .bg-layer{

    background: #4169E1;
}

.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}
.mainPart {
    margin-right: 30px;
    
}









a{
    text-decoration: none;

}


.banner{
    text-align: center;
    
    margin-bottom: 60px;
    background-color: #4169E1;
  

}

.section{
    display: unset;
    
}

a{
    text-decoration: none;

}


.stackWriting{
    margin: auto;
    margin-bottom: 30px;
}



.stackSection p{
    line-height: 1.2;
    font-size: 1em;


    
}
.stackCode{
    font-family: monospace;
    margin-right:20px;
    margin-left: 10px;
    margin-bottom: 40px;

}
.rules{
    margin:20px;
}

.stackSection #stackHighlights{
    background-color: rgb(247, 245, 245);
    
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 1.2em;
    font-family: 'Merriweather Sans', sans-serif;


}
#img{
    display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
 
}
#indent{
    text-align: center;
    margin: 40px;
}


.stackSection h4{
    font-size: 0.9em;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right:20px;
    margin-left: 10px;
    font-family: 'Merriweather Sans', sans-serif;

}

.stackAccordion h2{
    color: white;
	padding:20px;
    
}
details{
  color: black;
}
summary{
  

    margin-top: 20px;
    

}

.stackAccordion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #DB1F48;
  color: #444;
  cursor: pointer;
  text-align: center;
  border: none;
  outline: none;
  width: 280px;

  /* Unified transition for multiple properties */
  transition: background-color 1s, box-shadow 0.4s ease, transform 0.4s ease;
}
  .stackAccordion h2{
    font-size: 1.3em;
  }
  
.panel{
    text-align: left;
  
    padding: 0 18px;
    background-color: white;
    


}
.stackAccordion:hover {
  background-color: #C11A3A; /* Change to a darker shade on hover */
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  transform: scale(1.05); /* Slight scaling on hover */
}
@media only screen and (min-width: 768px){
       
   
  

    .stackSection p {
       
        font-size: 1em;

    }
    .stackSection {width: 600px;
        margin:auto;
        margin:auto;
    }
   
    .stackSection .csharp{
        margin-left: 40px;
        font-size: 1.5em;
        margin-bottom:100px;
		margin-top:40px;


    }
   
  
	.stackSection .stackHighlights{
		font-size: 1.7em;
        padding-left: 15px;
	}

}
@media only screen and (min-width: 820px){
    
 
  
  
   
    .stackSection .stackHighlights{
        font-size: 1.6em;
    }
  
 
    
}
  
@media only screen and (min-width: 1024px){
    .stackSection{
        width: 700px;
    }

   .sidenav{
        display: block;
    }
    
  

}

