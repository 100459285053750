@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;500&family=Nunito:wght@800&family=Roboto&display=swap');

.donateandmaincon{
    width:100%;
}

.banner{
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-bottom: 90px;
    background-color: #4169E1;
    display: flex;
    flex-direction: column;
    font-family: 'Merriweather Sans',   sans-serif;
    font-size: 0.6em;



}
.banner h2{
	font-size:2.2em;
}
.banner p{
  
 
    color: whitesmoke;
    
  
    
  }

h2{
    padding-top: 40px;
    text-align: center;
    text-decoration: none;
    position: relative;
    padding-bottom: 20px;
    color: whitesmoke;
    font-family: sans-serif;
 
}
.banner p{
    line-height: 1.4;
    font-size: 1.4em;
    text-align: center;
    color: whitesmoke;
    padding-left: 10px;
    padding-right: 10px;
    

    
}



#mainContent{
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#mainContent #aspects{
    text-align: center;
}

.buttons{

    text-align: center;
    width:100%;
    display: flex; 
    align-items: center; 
    flex-direction: column;
    justify-content: space-around; /*some space between cards*/

    margin-top: 20px;


}
#mainContent #aspects #aspectsLink{
    text-decoration: none;

    text-align: center;
    font-size: 1.3em;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    color: #004369;
  
}
#probuttons1{
  

    background-color: #004369;

    color: #fff;
    transition: background-color 1s;
    border-radius: 3px;
    font-size: 1.21em;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
    margin-bottom: 30px;
    border: none;
    border-radius: 4px;
    width: 270px;
    height:130px;
   padding-left:20px;
   padding-right:20px;
   

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;



}
#probuttons2{
    background-color: #DB1F48;

   color: #fff;
   transition: background-color 1s;
   border: none;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   border-radius: 4px;
   font-size: 1.21em;
   margin-bottom: 30px;
   cursor: pointer;
   font-family: 'Nunito', sans-serif;
   width: 270px;
   height:130px;
   padding-left:20px;
   padding-right:20px;
  
   

}

#probuttons3{
    


   background-color: #004369;
   color: #fff;
   transition: background-color 1s;
   border: none;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   border-radius: 4px;
   font-size: 1.21em;
   cursor: pointer;
   font-family: 'Nunito', sans-serif;
   width: 270px;
   height:130px;
   padding-left:20px;
   padding-right:20px;
   

}


#mainContent #aspects #aspectsLink::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #18272F;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;

}
#mainContent #aspects #aspectsLink:hover::before{
    transform-origin: left;
    transform: scaleX(1);

}
 #probuttons1:hover{
    background-color: #DB1F48;


}
#probuttons2:hover{

    background-color: #004369;


}
 #probuttons3:hover{
    background-color: #DB1F48;

    

}
#probuttons1:focus,
#probuttons2:focus,
#probuttons3:focus{
    background-color: black;
    transition: none;


}


@media (orientation: landscape) {
    #mainContent{
        display: flex;
    }
}
@media only screen and (min-width: 768px){
    
   
   
    .banner h2{
        font-size: 2em;
    }
    .banner p{
        font-size:2.2em ;
        
    }
   
    #probuttons1{
        font-size: 1.5em;
        width: 300px;
    }
    #probuttons2{
        font-size: 1.5em;
        width: 300px;

    }
    #probuttons3{
        font-size: 1.5em;
        width: 300px;

    }
    
}
@media only screen and (min-width: 820px){
    .banner h2{
        font-size: 4em;
    }
    
    
    
 



}

@media only screen and (min-width: 1200px){
    
    #probuttons1{
        font-size: 1.5em;
        width: 400px;
    }
    #probuttons2{
        font-size: 1.5em;
        width: 400px;

    }
    #probuttons3{
        font-size: 1.5em;
        width: 400px;

    }
    
}



