
 
 
 
  
  #topicsSection{
    margin-top: 50px;
    margin-bottom: 100px;
   
    font-family: 'Nunito', sans-serif;
    
    
    
  
  }
  .topicBanner{

    text-align: center;
   
    background-color: #4169E1;
    display: flex;
    flex-direction: column;
    font-size: 0.6em;

  }
  .topicBanner h2{
    font-size: 2.2em;
    padding-bottom: 30px;
    padding-top: 30px
  }
  

  
  .cardLink{
    text-decoration: none;
    text-align: center;
   
      text-decoration: none;
      color: white;
  
  }

  
  
  
  
  
  .cards_wrap{

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      margin:auto;

      row-gap:20px;
      
  }
  
  .cards_wrap .card_item{
      
     
      cursor:pointer;
      



      
  }
  
  .cards_wrap .card_inner{
    background-color: #004369;
    height: 170px;
    width: 250px;

   

    transition: background-color 0.7s;

      border: none;
      color:white;
      border-radius: 5px;
      padding: 35px 20px;


      
     

  }
  
 
 
  



.cards_wrap .card_inner:hover{
  background-color: #DB1F48;


}
.cards_wrap .card_inner:focus{
    background-color: black;
    transition: none;


}

  
  
  @media only screen and (min-width: 768px){
    #topicsSection{
      margin-top: 60px;
      margin-bottom: 50px;
    }
    
    .cards_wrap{
        
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 40px;
        width: 700px;
    }
    .cards_wrap .card_inner{

        height:180px;
        width: 300px;
  
    }
    .topicBanner h2{
      font-size: 3em;
    }
    
   
 
  
 
  
 


 
        
        
}

@media only screen and (min-width: 1200px){
 
  
        
}

 
  
 
  
  