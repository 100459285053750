
/*ads*/


::selection{
  color: #fff;
  background: #9b27ca;
}
.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}
.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;

  background:  #DB1F48;
  transition: background-color 0.8s;
}
.btn:hover .bg-layer{

    background: #4169E1;
}
.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}




#highlight{
    background-color: rgb(247, 245, 245);

}
.sortingSection{
    font-family: "euclid_circular_a", "Arial", "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    margin: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 200px;
}

#img{
    display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
   width: 306px;
    height: 100.2px;
}
#imgM{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 295px;
    height: 197px;
}
.sortingSection h4{
    font-size: 0.9em;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right:20px;
    margin-left: 10px;
    font-family: 'Merriweather Sans', sans-serif;

}


.sortingSection .nodesanded{
    margin-bottom: 30px;
   

}
.sortingSection .nodesanded p{
    line-height: 2;
}
@media only screen and (min-width: 768px){
    .sortingSection{
        width: 600px;
        margin-left:  auto;
        margin-right: auto;
        margin-bottom: 250px;        
    }
	
  

    
   

    #img{
     
       width: 481px;
        height: 167px;
        margin-bottom: 80px;
    }
    #imgM{
        
        width: 512px;
        height: 341px;
    }


	


}
@media only screen and (min-width: 820px){
  
    

    #img{
     
        width: 581px;
         height: 267px;
     }
     #imgM{
         
         width: 612px;
         height: 441px;
     }
 
    
}
  
@media only screen and (min-width: 1024px){
    .sidenav{
        display: block;
    }
    .sortingSection{
        width: 700px;
    }
  
 

   
   

}

