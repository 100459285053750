

/*ads*/

::selection{
  color: #fff;
  background: #9b27ca;
}
.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}
.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;

  background:  #DB1F48;
  transition: background-color 0.8s;
}
.btn:hover .bg-layer{

    background: #4169E1;
}
.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

#select{
	text-align:center;
    margin-top: 30px;
    margin-bottom: 20px;;

    
}

#select a{

    font-family: 'Merriweather Sans',   sans-serif;
    font-size: 1.2em;
    position: relative;
    cursor: pointer;
	padding-top:20px;
    text-decoration: none;
    color: #004369;
   
}
#lettercount, #primenumbertitle, #createword1{
    margin-top: 30px;
}
/* Style the buttons that are used to open and close the accordion panel */
.accordion h2{
    color: white;
	padding-top:20px;
}
.accordioncontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;

   

}
.accordion {
    background-color: #DB1F48;
    transition: background-color 1s;
    transition: 0.4s;


    color: #444;
    cursor: pointer;
    padding: 0px 70px;
    text-align: center;
    outline: none;
    width: 280px;
    height: 100px;
  }
  
.panel,.panel2,.panel3,.panel4{
    transition: 0.4s;

    text-align: left;
    display: none;
    padding: 0 18px;
    background-color: white;
    display: none;



}
.panel{
    height: 830px;
    width: 100%

}
.panel3{
    height: 9000px;
}

.accordion, p{
	padding-top: 8px;
	padding-bottom: 30px;
}


#amountofletterspaper, #primenumber, #createword{
    width: 320px;
    height: 320px;
	display: block;
  margin-left:auto;
  margin-right: auto;

}
#sc1, #sc2, #sc3, #sc4{
    display: block;
    margin-left:auto;
    margin-right: auto

}

#sc1{

    width: 250px;
    height: 245px;
	padding-bottom: 20px;
	
}
#sc2{

    width: 298px;
    height: 406px;
	padding-bottom: 20px;
    margin-top: 20px;

}
#sc3{
    width: 421px;
    height: 338px;
    padding-bottom: 20px;
    margin-top: 20px;
}
#sc4{
    padding-bottom: 20px;
    margin-top: 20px;
    width: 320px;
    height: 242px;

}
.dontHide{
    display: block;
}
.hide{
    display: none;
}
#techni{
    text-align: center;
    margin-top: 10px;
    font-size: 1.2em;
}
#techni a{
    color: purple;
}


  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
    background-color: #004369;
  }
  

#select a::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #18272F;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}
#select a:hover::before{
    transform-origin: left;
  transform: scaleX(1);

}

  
@media only screen and (min-width: 768px){
  
    .banner h2{
        font-size: 2.3em;
      
    }
    .accordion{
        width: 400px;
    }
    .panel,.panel2,.panel3,.panel4{
        width: 400px;
        height: 100%;
    }
   
   
    
}  
@media only screen and (min-width: 820px){
    .sidenav{
        display: block;
    }
    
    .banner h2{
        font-size: 2.3em;
        padding-left: 20px;
        padding-right: 20px;
    }
    #select a{
        font-size: 1.3em;
    }
   
    .accordion{
        width: 500px;
    }
    .panel,.panel2,.panel3,.panel4{
        width: 500px;
    }
   
    .accordion h2{
        font-size: 1.6em;
    }
    .panel p{
    font-size: 1em;
    }
   
    #techni{
        font-size: 1.7em;
    }
  
    #sc4{
        padding-bottom: 20px;
        margin-top: 20px;
        width: 687px;
        height: 542px;
    
    }
    
    

}
@media only screen and (min-width: 1200px){
  
        

   
   
    .socials, #donation p{
        font-size: 1em;
    }
    #techni{
        font-size: 1.3em;
    }
    #sc1{

    margin-left: 20px;
    margin-right: 20px;;
    height: 345px;
	padding-bottom: 20px;
	
    }
    .accordion,    .panel,.panel2,.panel3,.panel4{width: 600px;
        margin:auto;
    }
 
	
	#sc3{
        width: 621px;
        height: 538px;
        
    }
    
    #amountofletterspaper, #primenumber, #createword{
    width: 520px;
    height: 520px;
	
    }


    
       
}
  
 