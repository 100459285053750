
footer{
    width:100%;
    background-color: #333;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    color:white;
    

}
footer #donation{
    display: flex;
}

.support{
    display: flex;
    font-size: 1.3em;
    padding-top: 20px;
    border-bottom: dashed white 1px;
    margin-bottom: 20px;
    width: 30%;

}
.support #heart{
    color: red;
}

#donation #donate{
    margin-right: 20px;
    font-size: 1.3em;
    padding: 10px 10px;
    border: none;
    border-radius: 3px;
    background-color: #54b3d6;;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    transition: transform .2s;



}


#donation p{
    padding-right: 10px;
    padding-bottom: 10px;
    font-size: 1em;
    
}
.socials h5{
    font-size: 1em;

}
.socials p{
    font-size: 1em;
    padding-left: 10px;
    padding-bottom:5px ;

}
.socials{
    padding-top: 10px;
    padding-bottom: 20px;


    
}
#donation a:hover{
	transform: scale(1.1);
	font-size:1.1em;

}
@media only screen and (min-width: 768px){
    
    .support{
        font-size: 1.5em;
        width: 17%;
    }
    footer{
        align-items: center;
    }
    #donate{
        padding-left: 620px;
    }
    #donation p{
        margin-right:300px;
        font-size: 1em;
    }
    #heart{
        padding-left: 7px;
    }

}