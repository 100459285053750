/*ads*/

::selection{
  color: #fff;
  background: #9b27ca;
}

.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out, transform 0.2s 0s ease-in-out;
}

.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
}

.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .content{
  flex-direction: column;
}

.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}

.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}

.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}

.content h2{
  margin-top: 35px;
  font-size: 32px;
}

.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}

.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}

.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: #DB1F48;
  transition: background-color 0.8s;
}

.btn:hover .bg-layer{
  background: #4169E1;
}

.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.banner{

  text-align: center;
 
  margin-bottom: 40px;
  background-color: #4169E1;
  font-family: 'Merriweather Sans', sans-serif;
}

.banner h1{
  color: white;
  font-size: 1.3em;
}

#highlight{
  background-color: rgb(247, 245, 245);
}

.graphTravSection{
  font-family: "euclid_circular_a", "Arial", "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 200px;
  text-align: center; /* Ensures text and images are centered */
}
.graphTravSection p, .graphTravSection h3{
  text-align: left;
}



.graphTravSection p{
  line-height: 1.6;
  margin-right: 20px;
  margin-left: 20px;
}

.rules{
  margin: 20px;
}

.graphTravSection h3{
  width: 100%;
  margin-bottom: 10px;
  margin-top: 30px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.2em;
  font-family: 'Merriweather Sans', sans-serif;
}

#graphTravImg{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 100%; /* Ensures responsiveness */
}

#overflow{
  overflow-wrap: break-word;
}

.graphTravSection h4{
  font-size: 0.8em;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  font-family: 'Merriweather Sans', sans-serif;
}

@media only screen and (min-width: 768px){
  
  .graphTravSection{
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 300px;
  }
  
 
  

}

@media only screen and (min-width: 820px){
  /* Additional styles can be added here if needed */
}

@media only screen and (min-width: 1024px){

 .graphTravSection{
  width: 700px
 }
}
