
/*ad blocker*/

::selection{
  color: #fff;
  background: #9b27ca;
}
.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}
.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;

  background:  #DB1F48;
  transition: background-color 0.8s;
}
.btn:hover .bg-layer{

    background: #4169E1;
}
.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}


.banner{
    padding-top: 20px;
    padding-bottom: 25px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    background-color: #4169E1;
    font-family: 'Merriweather Sans',   sans-serif;
}


.banner h1{
	font-size:2.2em;
	padding-top: 20px;
    text-decoration: none;
    position: relative;
    padding-bottom: 20px;
    color: whitesmoke;
    font-family: sans-serif;

}



/*ads*/
.sidenav {
  display: none;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;
 
  position: absolute; 
  right: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-bottom: 20px;
  height: auto; /* Full-height: remove this if you want "auto" height */
  width: 310px; /* Set the width of the sidebar */

}
.adsbygoogle{
  margin-bottom: 20px;
}



#highlight{
    background-color: rgb(247, 245, 245);
    color: black;
    text-align: left;
    font-size: 1em;

}

#backusSection{
    margin: auto;
    margin-left: 20px;
    margin-right: 20px;
    
}
#backusSection p{
    margin-right: 20px;
    margin-left: 20px;
}

.rules{
    margin:20px;
}
#backusSection h3{
    
    width: 100%;
    margin-bottom: 10px;
    margin-top: 30px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;
    font-family: 'Merriweather Sans', sans-serif;


}
#backusImg{
    display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
 
}
#overflow{
    overflow-wrap: break-word;
    margin-bottom: 10px;
}

#backusSection h4{
    font-size: 0.8em;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
    font-family: 'Merriweather Sans', sans-serif;

}
#indent{
    text-align: center;
    margin: 40px;
}

@media only screen and (min-width: 768px){
   
	#backusSection{
        margin: auto;
        width: 600px;
    }
  
}
@media only screen and (min-width: 820px){
    
   
 
    
}
  
@media only screen and (min-width: 1024px){
  .sidenav{
        display: block;
    }
  #backusSection{
    width: 700px;
  }


}

