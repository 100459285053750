@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;500&family=Nunito:wght@800&family=Roboto&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
body{
    min-height: 100vh; 
  
    font-family: 'Merriweather Sans',   sans-serif;

}

#root{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.App {
    width: 100%;
    min-height: 100vh; 
    
    
    
 }
html {
min-height: 100vh; 

}
header{
    background-color: #333;
    width: 100%;
    padding-bottom: 10px;
 
}
.fa-solid-glasses{
    margin-top: 10px;
    font-size: 0.6em;
    -webkit-text-stroke: 0.1px;

    margin-bottom: 0px;
     color:white;
}

.iconContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

    
}
.iconContainer .icon-link-styles{
    display: flex;
    flex-direction: column;
	text-decoration: none;
	
	align-items: center;
    justify-content: center;
}
h1{
    font-size: 0.6em;
    font-family: 'Nunito', sans-serif;
    color:white;


    

}

#navbar{
	
    text-align: center;
    position: relative;
   overflow: hidden;
   padding-top:10px;

}

#navbar li{
    list-style: none;
    font-family: 'Nunito', sans-serif;
    display: inline-block;
    font-size: 0.7em;
    padding: 0px 10px;



}

#navbar li .link-styles{
    
    color:white;
    transition: all 0.3s ease 0s;
    text-decoration: none;


}
#navbar li .link-styles:hover{
    color:rgb(67, 67, 196);

}
#navbar li:focus,
#navbar li:focus{
    color: black;
    transition: none;
}
#navbar li .link-styles:hover{
     color: #0088a9;
} 
#donation .link-styles:hover{
	transform: scale(1.1);
	font-size:1.1em;

}



@media only screen and (min-width: 768px){

    
    #navbar li .link-styles{
        padding: 10px 5px;
    
    }
    .iconContainer h1{
        font-size: 0.8em;
    }
    .fa-solid-glasses{
        font-size: 0.8em;
        
    }
   
}
@media only screen and (min-width: 820px){

 


}

@media only screen and (min-width: 1200px){
    #navbar li .link-styles{
        font-size: 1.2em;
        padding-left: 20px;
        padding-right: 20px;
    }
 

    
}



