
.sidenav {
  display: none;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;

  position: absolute; 
  right: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-bottom: 20px;
  height: auto; /* Full-height: remove this if you want "auto" height */
  width: 310px; /* Set the width of the sidebar */

}
.adsbygoogle{
  margin-bottom: 20px;
}


/*ads*/

::selection{
  color: #fff;
  background: #9b27ca;
}
.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
}
.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;

  background:  #DB1F48;
  transition: background-color 0.8s;
}
.btn:hover .bg-layer{

    background: #4169E1;
}
.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.hide{
  display: none;
}
.dontHide{
  display: block;
 
}
.mainHide{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  row-gap: 20px;
}

.banner{
    text-align: center;

    
    margin-bottom: 35px;
    background-color: #4169E1;
  
    font-family: 'Merriweather Sans',   sans-serif;



}
.banner h2{
	font-size:2.2em;
  padding-bottom: 0;
  margin: auto;  
  
}
.banner p{
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;

}
#compSystemSection{
   
    font-family: 'Merriweather Sans',   sans-serif;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    

}
 
h2{
    padding-top: 40px;
    text-align: center;
    text-decoration: none;
    position: relative;
    padding-bottom: 20px;
    color: whitesmoke;
    font-family: sans-serif;
 
}


a{
    text-decoration: none;

}


.buttons{
    text-align: center;


}

#orderedButton{
   margin-right: 20px;
   margin-left: 20px;
   padding: 50px 30px;



   margin-top: 20px;
   background-color: #DB1F48;
   color: #fff;
   transition: background-color 1s;
   border-radius: 4px;
   font-size: 1.21em;
   cursor: pointer;
   font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
   margin-bottom: 30px;
   border: none;
   width: 80%;

   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;



}
#randomButton{
  padding: 50px 30px;
  margin-top: 10px;
  background-color: #004369;
  color: #fff;
  transition: background-color 1s;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;
  font-size: 1.21em;
  margin-bottom: 100px;
  cursor: pointer;
  font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
  width: 80%;
  margin-right: 20px;
  margin-left: 20px;

}





/*flashcards*/
.lesserHide{
  margin-bottom: 200px;
  
}
#compSystemSection #flipMe {
    margin: 20px;
  
  }
  .scene {
    width: 330px;
    margin-top: -100px;

  
    height: 350px;
    border-radius:100px;
  
    perspective: 1200px;
  
  
  }
  
  .card {
  
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
    
    
    
  }
  
  .cardFlip{
    transform: rotateY(180deg);
    
    
  
  }
  
  .card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    justify-content: center;
    padding-top: 30%;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

  }
  #spacing{
    text-align: center;
    
    max-width: 85%;
    max-height: 80%;
    overflow-wrap: break-word;
  }

  .card__face--back {
    
    transform: rotateY(180deg);
   
  }
 
  #front{
    font-size: 1.5em;
  }
  #back{
    padding: 0px;
    font-size: 1.3em;
    line-height: 23px;

    
  }
  .backandNext{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;


  }
  #compSystemSection #completed{
    text-align: center;
    display: none;

  }
  #compSystemSection #number{
    font-size: 1em;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0;
   
  }
 
  .nextbuttons{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #next2{

  padding: 10px 20px;
  background-color: #004369;
  color: #fff;
  transition: background-color 0.6s;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;
  font-size: 1.21em;
  cursor: pointer;
}
  #next, #previous{

    display: block;
    padding: 10px 40px;
    background-color: #004369;
    color: #fff;
    transition: background-color 0.6s;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 4px;
    font-size: 1.21em;
    cursor: pointer;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
    margin-right: 30px;
  }
 
  .accordianCon{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

 
}
.accordion {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #DB1F48;
  transition: background-color 0.6s;
  width: 200px;
  height: 70px;
  text-align: center;
  


  color: #444;
  cursor: pointer;
  padding: 0px 70px;
  border: none;
  outline: none;
  transition: 0.4s;
}

.accordion h2{
  position: unset;


    padding: 0px 0px;
    font-size: 1.5em;
    

    color: white;
}

  .uniqueQues{
    
    border-radius: 5px; 
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
    margin-top: 20px;

  }
  .dontHide .panel{

    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 8200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
   
    

    overflow:  hidden;


}
.individualQ{
  text-align: left;


 

}
.uniqueQues{
  padding-top: 20px;
  width: 300px;
  height: 190px;
}
.ques{
  margin-bottom: 10px;
  text-align: left;
}
.ans{
  padding-bottom: 20px;
  text-align: left;
}
.uniqueQues p{
    font-size: 0.8em;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 20px;
    margin-top:0px;
}



#compSystemSection #next:hover, #previous:hover{
    background-color: #DB1F48;
}
#compSystemSection #orderedButton:hover{
  background-color: #004369;

}
#compSystemSection #randomButton:hover{
  background-color: #DB1F48;


}
#next:focus,
#previous:focus{
    transition: none;


}

#compSystemSection #next2:hover{
    background-color: #DB1F48;
}
#next2:focus{
    transition: none;
  
  
  }
  .accordian:hover{
    width: 300px;


  }



@media only screen and (min-width: 768px){
  
    #orderedButton,
    #randomButton{
      font-size: 1.7em;

    }
        
    .banner p{
        font-size:2.2em ;
    }
    .scene {
        width: 400px;
        height: 320px;
 
    }
    #front{
        font-size: 1.3em;


    }
    
    #back{
        font-size: 1.2em;
      }
     
   
    .backandNext{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        
    
    
      }
      #compSystemSection #completed{
        text-align: center;
        font-size: 1.4em;
    
      }
      #compSystemSection #number{
        text-align: center;
        margin: 5px;
        font-size: 1.2em;
      }
      #next2{
        margin-top: 20px;
        padding: 10px 50px;
        
        font-size: 1.5em;
        
      }
      .cardQuestion .ques, .cardAnswer .ans{
        font-size: 1em;
      }
      #next, #previous{
        padding: 10px 50px;
        
       
        font-size: 1.9em;
        cursor: pointer;
        margin-right: 60px;
      }
      
    .uniqueQues{
      display: flex;
      flex-direction: row;


      width: 400px;

    }
    
    .cardQuestion{
      width: 40%;
      border-right: black 1px solid;
      height: 60%;
      word-wrap: break-word;
      
    }
    .cardAnswer{
      width: 60%;
    }
   
    
    
    
      
   
}
@media only screen and (min-width: 820px){
   
    .scene{
        width: 400px;
        height: 370px;
    }
    #front{
        font-size: 1.3em;
    }
    #back{
        font-size: 1.3em;
        line-height: 20px;
    }
   
   



}

@media only screen and (min-width: 1024px){
  .panel{
    width: 100px;
  }

  .sidenav{
      display: block;
      margin-top: 100px;
  }
  
    
    #front{
        font-size: 2em;


    }
    #back{
        font-size: 1.8;
        line-height: 25px;
    }
    .scene {
        width: 500px;
        height: 400px;
  
        
      
    }
    

      #compSystemSection #completed{
        text-align: center;
        font-size: 1.7em;
    
      }
      #compSystemSection #number{
        text-align: center;
        margin: 10px;
        font-size: 1.5emS;
      }
     
      
      
      
    
    
}



