
/*ads*/


::selection{
  color: #fff;
  background: #9b27ca;
}
.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}
.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;

  background:  #DB1F48;
  transition: background-color 0.8s;
}
.btn:hover .bg-layer{

    background: #4169E1;
}
.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}












a{
    text-decoration: none;

}

.banner{
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    background-color: #4169E1;
    font-family: 'Merriweather Sans',   sans-serif;
}
.banner h2{
    color: white;
    font-size: 2.2em;
}

#programmingTheorySection{
    margin-left: 20px;
}
#programmingTheorySection h1{
    padding-top: 20px;
    padding-bottom: 25px;
    
}

#programmingTheorySection h4 a{
    box-shadow: inset 0 0 0 0  #DB1F48;
  color: #004369;
  margin: 0 -.25rem;
  padding: 0 .25rem;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;


}
#programmingTheorySection h4{
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
    

}

#para{
    text-align: center;
    margin: 50px;
    margin-left: 20px;
    margin-right: 20px;
}



#programmingTheorySection h4 a:hover {
    box-shadow: inset 1000px 0 0 0  #DB1F48;
    color: white;
}
@media (orientation: landscape) {
    #programmingTheorySection{
        display: flex;
        justify-content: space-around;
    
    } 
}
@media only screen and (min-width: 768px){
   
    #programmingTheorySection{
        margin-left: 30px;
        display: flex;
        justify-content: space-around;
    }
    
    
   
    #programmingTheorySection h4{
        font-size: 1.3em;
    }
    
 
    
}
@media only screen and (min-width: 820px){
  
  

}

@media only screen and (min-width: 1000px){
    .sidenav{
        display: block;
    }
    .bottomads{
        margin-right: 360px;
        margin-left: 50px;
    
    }
     
   
    #programmingTheorySection{
      margin-top: 100px;
      margin-bottom: 100px;
        justify-content: center;
        border-bottom:none;

        
        
    }
    #programmingTheorySection h4{
        padding-right: 80px;
        padding-left: 80px;
    }
   
    

    
}


