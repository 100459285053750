
/*ads*/
.sidenav {
    display: none;
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 0px;
   
    position: absolute; 
    right: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-bottom: 20px;
    height: auto; /* Full-height: remove this if you want "auto" height */
    width: 310px; /* Set the width of the sidebar */

  }
  .adsbygoogle{
    margin-bottom: 20px;
  }
::selection{
  color: #fff;
  background: #9b27ca;
}
.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}
.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;

  background:  #DB1F48;
  transition: background-color 0.8s;
}
.btn:hover .bg-layer{

    background: #4169E1;
}
.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}


#programmingNavBar{

    background-color: #4169E1;
    box-shadow: 0 0 10px 0 rgba(118, 91, 240, 0);
    cursor: pointer;
    margin-top: 30px;

}
#programmingNavBarContainer{
    padding-top: 10px;
    list-style-type: none;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align: center;

   

    

}

#programmingNavBarContainer li{
    display: flex;
    justify-content: center;
    align-items: center;


    background-color: white;
    
    border-radius: 3px;
    margin-bottom: 20px;
    height: 100px;
    width: 80%;
    max-width: 280px;
    min-width: 100px;


	


}
#programmingNavBarContainer li a{
    font-family: 'Merriweather Sans',   sans-serif;
    color: black;
    font-size:2em;
    transition: transform .2s;
    
    text-decoration: none;



}

.WTE, .WTP{
    width: 100%;
    
}
#WTP, #WTE{
    margin-top: 90px;
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 1.3em;
    font-family: 'Merriweather Sans', sans-serif;
    
}

#programmingSection ul{
    padding-left: 20px;

    margin-bottom: 20px;
    font-size: 1em;
   
    
}

#programmingSection{
    margin-right: 20px;
    margin-left: 20px;

    
}
#programmingSection p, ol{
	padding-left: 20px;
    line-height: 1.4;
    margin-bottom: 20px;
    font-size: 1em;
   
}

#programmingSection .csharp{
    padding-top: 20px;
    margin-left: 40px;


}

#WTE, #WTP, #loops, #max, #splitHead, #Length, #ListsAndArrays, #contains, #modulus{
    width: 100%;
    margin-bottom: 10px;
    background-color: rgb(247, 245, 245);
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
#programmingSection ol a{
    text-decoration: none;
    color: black;
    transition: all 0.3s ease 0s;

}

#programmingSection h4{
    margin-bottom: 10px;
    margin-top: 70px;
    font-size: 1.2em;
    
}
#learnList li{
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
    margin-left: 20px;
    padding-top: 10px;
    


}
#learnList{
    margin-bottom: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
    margin-left: 20px;
    background-color: aliceblue;
    width: 100%;

}
#programmingNavBar li a{
    background-color: unset;
}
#programmingNavBar li{
    transition: transform 0.3s ease, font-size 0.3s ease;

}
#indent{
    text-align: center;
    margin: 40px;
}
#programmingNavBarContainer li:hover{
	transform: scale(1.1);
	font-size:1.1em;

}
#programmingNavBar li a:hover{
    color: grey;
}
#programmingNavBar li:focus,
#programmingNavBar li:focus,
#programmingSection #loops, #max, #splitHead, #Length, #ListsAndArray, #contains, #modulus, a
{
    color: black;
    transition: none;
}


#donation a:hover{
	transform: scale(1.1);
	font-size:1.1em;

}
@media only screen and (min-width: 768px){
	
   .WTP, .WTE{
    margin: auto;
        width: 700px
   }
  
  
    #programmingSection p, ol{
     
       line-height: 1.6;
        
    }
	#programmingSection #WTP, #WTE{
		font-size: 1.4em;
	}
  
	#programmingSection .csharp{
		margin-bottom:100px;
		margin-top:40px;
	}
	

}
@media only screen and (min-width: 820px){
    
  
    #programmingNavBarContainer #highlight{
	    font-size: 3em;
    }

  
	
    #programmingNavBarContainer li:hover{
        transform: scale(1.1);
        font-size:0.9em;
    
    }
    #programmingNavBarContainer li{
        font-size: 0.7em;
        max-width: 400px;

    }
    
}
  
@media only screen and (min-width: 1024px){
   
  
    #highlight{
     
        font-size: 1.2em;
        
    }
    #programmingSection #WTP, #WTE{
        font-size: 1.5em;
    }
    #programmingSection h4{
        font-size: 1.5em;
    }
    .WTP, .WTE{
            width: 900px
       }
   
    

}






