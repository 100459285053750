

/*ads*/

::selection{
  color: #fff;
  background: #9b27ca;
}
.wrapper{
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}
.btn{
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.btn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;

  background:  #DB1F48;
  transition: background-color 0.8s;
}
.btn:hover .bg-layer{

    background: #4169E1;
}
.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}


.banner{
    padding-top: 20px;
    padding-bottom: 25px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    background-color: #4169E1;
    font-family: 'Merriweather Sans',   sans-serif;
}
.banner h1{
    color: white;
    font-size: 1.3em;
}
.banner{
    text-align: center;
    
    margin-bottom: 60px;
    padding: 10px;
    background-color: #4169E1;
    display: flex;
    flex-direction: column;
    font-family: 'Merriweather Sans',   sans-serif;
    font-size: 0.6em;
    border: none;

}
.banner h1{
	font-size:2.2em;
	padding-top: 20px;
    text-decoration: none;
    position: relative;
    padding-bottom: 20px;
    color: whitesmoke;
    font-family: sans-serif;

}


#highlight{
    background-color: rgb(247, 245, 245);
    padding:10px;

}
.graphSection{
    margin-left: 20px;
    margin-right: 20px;;
}
.graphSection p{
    font-size: 1em;
    font-family: "euclid_circular_a", "Arial", "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

.graphSection .csharp, .example, p{
    margin: 0px 10px;
    
    
}

.panel{
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.rules{
    margin:20px;
}

#graphImg{
    display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
 
}
#imgM{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 255px;
    height: 147px;
}
#overflow{
    overflow-wrap: break-word;
}


.graphAccordion h3{
    color: white;
	padding-top:20px;
}
.graphAccordionContainer{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
    width: 100%;
    
	
    

}
.graphAccordion {
    margin: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #DB1F48;
    color: #444;
    cursor: pointer;
    padding: 0px 40px;
    text-align: center;
    border: none;
    outline: none;
    width: 80%;
    max-width: 400px;

    /* Unified transition for multiple properties */
    transition: background-color 1s, box-shadow 0.4s ease, transform 0.4s ease, padding 0.4s ease;
}

.panel{
    text-align: left;
    padding: 0 18px;
    background-color: white;
    text-align: left;


}
.graphAccordion, p{
	padding-top: 8px;
	padding-bottom: 30px;
}





#indent{
    text-align: center;
    margin-top: 30px;
}
.graphAccordion:hover {
    background-color: #004369; /* Change to a darker shade on hover */
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    transform: scale(1.05); /* Slight scaling on hover */
}

@media only screen and (min-width: 768px){
    .graphSection{width: 600px;
        margin:auto;
    }

    .graphSection h4{
        margin-right: 50px;
        margin-left: 30px;
        font-size: 1.2em;

    }
    .graphSection .csharp{
        margin-left: 40px;
        font-size: 1.5em;
        margin-bottom:100px;
		margin-top:40px;


    }
   
    h1{
        font-size: 2em;

    }
    .banner h1{
        font-size: 2.3em;
    }
    .graphAccordion{
        max-width: 600px;
    }


   
    .graphAccordion h3{
        font-size: 1.4em;
    }

    #techni{
        font-size: 1.4em;
    }
    .panel p{
        font-size: 1.7em;
    }


	


}
@media only screen and (min-width: 820px){
  
   
 

    .panel p{
    font-size: 1em;
    }
    
  
 
    
}
  
@media only screen and (min-width: 1024px){
   
   .graphSection{
        width: 700px;
   }
   
    .graphSection .csharp{
       
        font-size: 1.4em;
     

    }
    #imgM{
        width: 455px;
        height: 247px;
    }
    .graphAccordion{
        max-width: 500px;
     
    }
    .allContainer{
        display: flex;

    }


}

