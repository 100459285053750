/* General Styles */
::selection {
  color: #fff;
  background: #9b27ca;
}

/* Wrapper Styles */
.wrapper {
  position: absolute;
  max-width: 480px;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px 30px;
  background-color: #004369;
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.06);
  transition: opacity 0.2s 0s ease-in-out, transform 0.2s 0s ease-in-out;
}

.wrapper.show {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
}

.wrapper .content,
.content .warn-icon,
.warn-icon .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .content {
  flex-direction: column;
}

.content .warn-icon {
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: #4169E1;
}

.warn-icon .icon {
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}

.warn-icon .icon i {
  background: #2691ce;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
}

.content h2 {
  margin-top: 35px;
  font-size: 32px;
}

.content p {
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}

.treeTravSection p {
  padding-bottom: 0;
}

.btn {
  height: 57px;
  width: 223px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}

.btn .bg-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: #DB1F48;
  transition: background-color 0.8s;
}

.btn:hover .bg-layer {
  background: #4169E1;
}

.content button {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

/* Banner Styles */
.banner {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
  background-color: #4169E1;
  font-family: 'Merriweather Sans', sans-serif;
}

.banner h2 {
  font-size: 1.3em;
}

/* Tree Trav Section Styles */
.treeTravSection {
  font-family: "euclid_circular_a", "Arial", "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  margin: auto;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 200px;
}

#highlight {
  background-color: rgb(247, 245, 245);
}

.treeTravSection .text {
  margin-top: 50px;
  margin-bottom: 50px;
}

#treeTravImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

#treeTravImg1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 123px;
  height: 63px;
}

/* Media Queries */
@media only screen and (min-width: 768px) {
  .treeTravSection {
    margin: auto;
    width: 600px;
    margin-bottom: 300px;
  }

  .treeTravImg {
    text-align: center;
  }
}

@media only screen and (min-width: 820px) {
  .treeTravSection {
    /* Add any additional styles for screens larger than 820px */
  }
}

@media only screen and (min-width: 1024px) {
  .treeTravSection {
    width: 700px;
  }
}

/* Accordion Styles */
.stackAccordioncontainer {
  margin: 20px 0;
}

.stackAccordion {
  background-color: #4169E1;
  color: #fff;
  border: none;
  padding: 10px 15px;
  text-align: left;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.stackAccordion:hover {
  background-color: #354a7a;
}

.panel {
  padding: 0 15px;
  background-color: #f1f1f1;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-radius: 0 0 5px 5px;
}

.panel p {
  margin: 15px 0;
}
